body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 4em 0 0 0;
}

main {
  flex: 1;
}

.bg-header {
  background-color: $color-header-bg;
}

.btn-outline-primary {
  color: $color-primary;
  border-color: $color-primary;
}
