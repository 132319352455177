/*
  Font variables
*/
$font-main: 'Roboto', sans-serif;

/*
  Breakpoints
*/
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

/*
  Color and color maps
*/

$color-white: #fff;
$color-primary: #5599db;
$color-header-bg: #363936;

// User login/password reset colors
$color-map-admin-user: (
  primary: #5599db,
  green: #00ce81,
  label:#555555,
  link: #808080,
  link-hover: #b23b3b
);

/*
  Bootstrap theming
 */

$body-bg: #fafafa;
$body-color: #333;

$theme-colors: (
  "primary": #5599db,
  "lightbulb": #ffd200,
);
