.selectize-input {
  border: none;
  box-shadow: none;

  &.focus {
    box-shadow: none;
  }
}

.selectize-control {
  height: auto;
  padding-bottom: 0;
}

.selectize-dropdown {
  height: auto;
}

.selectize-dropdown-content {
  .option {
    background-color: $color-white;
  }
}
